/* Menu item bottom border  */
/* Should be added inside MenuBar.tsx via props or overrides or something else*/
#root
  > div
  > div.MuiGrid-root.MuiGrid-container.MuiGrid-item.MuiGrid-direction-xs-column
  > header
  > div
  > div
  > span {
  background-color: white;
}

#AppBar svg.MuiSvgIcon-root {
  margin: 0;
  padding: 0 0.25rem;
}

#AppBar a {
  padding: 0;
  min-height: 48px;
}