.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Add new item button */
/* button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit {
  background-color: #0c69b1;
  color: white;
  transition: transform 0.3s ease-out, background-color 0.3s ease-out;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
}

button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit:hover {
  transform: scale(1.1);
  background-color: #2f8ad1;
} */

/* hover effect on table rows */
tr:hover {
  background-color: #f1f1f1;
}

/* Loading animation customization*/
.loadingAnimationDialog > div {
  background-color: transparent !important;
}
.loadingAnimationDialog .MuiDialog-scrollPaper > div {
  box-shadow: 0 0 0 transparent;
  overflow: hidden;
  margin: 0;
  background-color: transparent;
}

/* Style inputs in Edit Pages*/
.inputBoxStyle {
  margin-top: 2rem !important;
}
.inputStyle {
  margin: 1rem 0 !important;
  padding: 0.5rem 0.5rem 0 0.5rem;
  /* background-color: lightgreen; */
}
.inputStyle--clearPadding {
  padding: 0;
}
.inputStyle--marginTop2rem {
  margin-top: 2rem !important;
}

.table-list-pagination-bottom > div div:not(.detail-panel-history-table) > .MuiTable-root {
  table-layout: fixed !important;
}

/* bigger buttons on the bottom */
.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit {
  padding: 10px !important;
}
/* hidden header for mobile max 600px */
@media screen and (max-width: 600px){
  .MuiContainer-root {
    margin-top: 7rem;
  }
}

/* Footer position to bottom in the Table List Pages */
.table-list-pagination-bottom .MuiTablePagination-toolbar {
  bottom: 0;
  left: 0;
  position: fixed;
  background-color: #e4e4e4;
  border-top: 1px solid #cccccc;
  padding: 0 2rem;
}
@media screen and (max-width: 600px) {
  .table-list-pagination-bottom .MuiTablePagination-toolbar {
    position: fixed;
    bottom: 48px;
    padding: 0;    
  }
}