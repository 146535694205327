.art-items-container {
    display: flex;
    height: 100%;
    flex-direction: column;
}
.art-items-list {
    flex-grow: 1;
}
.art-items-container > div {
    margin-top: 10px;
}

.MuiCardLocation {
    background-color: rgba(220, 89, 45, 0.9) !important;
}