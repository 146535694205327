body {
  margin: 0;
  background-color: #e4e4e4;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100vh;
}

.section-header {
  font-weight: bold;
  font-size: larger;
  vertical-align: text-bottom;
}

.MuiTileGrid .MuiCard-root {
  height: 300px;
  position: relative;
}

.MuiTileGrid .MuiCardBackground {
  width: 100%;
  height: 60%;
  position: absolute;
  bottom: 0px;
  z-index: 1;
  background: #000;
  background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(0,0,0,0.5) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(0,0,0,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(0,0,0,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#80000000',GradientType=0 ); /* IE6-9 */
}

.MuiTileGrid .MuiCardImage {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0;
}

.MuiTileGrid .MuiCardImage img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.MuiTileGrid .MuiCardHeader-root {
  position: absolute;
  z-index: 2;
  bottom: 16px;
  color: #FFF;
}

.MuiTileGrid .MuiCardContent-root {
  position: absolute;
  z-index: 2;
  bottom: 0;
  color: #DDD;
}

.MuiTileGrid .MuiCardActions-root {
  top: 0;
  /* padding-left: 15px!important;
  padding-top: 15px!important; */
}

.MuiTileGrid .MuiIcon-root {
  color: #FFF;
}

.MuiTileGrid .MuiGrid-spacing-xs-4 {
  width: calc(100% + 16px)!important;
  margin: 0!important;
  margin-left: -16px!important;
}

.MuiTileGrid .MuiCardLocation {
  z-index: 2;
  position: absolute;
  right: 15px;
  top: 0px;
  background: #DC592D;
  color: #FFF;
  padding-left: 15px; 
  padding-right: 15px;
  padding-bottom: 5px;
  border-radius: 0 0 6px 6px;
}

.MuiTileGrid .MuiCardLocation.GrayedOut {
  background: #798A93;
}
.MuiTileGrid .MuiCardLocation.Borrowed {
  background-color: #2868B0 !important;
}
.MuiTileGrid .MuiCardLocation:empty {
  display: none;
}

.PopupImageContainer {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 1;
  overflow: hidden;
  margin: 0;
}

.PopupImageContainer img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  -o-object-fit: scale-down;
  object-fit: scale-down;
  height: 100%;
  width: 100%;
}

.edit-buttons {
  display: flex;
  flex-direction: row-reverse;
}

.edit-buttons button{
 margin-left: 1rem;
 width: auto;
}

.edit-buttons button:last-child{
  margin-left: 0;
 }

.MuiInputLabel-asterisk {
  color: red;
}

/* Edit Page Table Header */
div.MuiToolbar-regular {
  background-color: #eaeaea;
  display: flex;
  justify-content: space-between;
}
.MuiToolbar-regular > div:nth-child(2) {
  display: none;
}
.MuiToolbar-regular > div:nth-child(3) {
  /* BE CAREFUL THAT THIS STYLE AFFECTS THE TILE VIEW ALSO!!! */
  /* width: 600px; */
  box-shadow: none;
}
.MuiToolbar-regular > div:nth-child(3):hover {
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

/* styles for '...' */ 
.block-with-text {
  /* hide text if it more than N lines  */
  overflow: hidden;
  /* for set '...' in absolute position */
  position: relative; 
  /* use this value to count block height */
  line-height: 1.2em;
  /* max-height = line-height (1.2) * lines max number (3) */
  max-height: 2.4em; 
  /* fix problem when last visible word doesn't adjoin right side  */
  text-align: justify;  
  /* place for '...' */
  margin-right: -1em;
  padding-right: 1em;
}
/* create the ... */
.block-with-text:before {
  /* points in the end */
  content: '...';
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of block */
  right: 0;
  bottom: 0;
}
/* hide ... if we have text, which is less than or equal to max lines */
.block-with-text:after {
  /* points in the end */
  content: '';
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of text */
  right: 0;
  /* set width and height */
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  /* bg color = bg color under block */
  background: white;
}
.MuiTableRow-root:hover .block-with-text:after {
  background: #f1f1f1;
}
